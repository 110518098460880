import Logos from "../Logos/Logos";
import "./Header.css";

const Header = () => {
  return (
    <section id="header" className="header">
      <div className="hero-content">
        <div className="hero-title">Code That Conquers</div>
        <div className="hero-subtitle">
          From concept to launch, SODA's elite team crafts bespoke solutions
          that shatter <br /> expectations. Your groundbreaking idea meets our
          cutting-edge expertise.
        </div>
        <a href="#contact">
          <button className="hero-button">Get Started</button>
        </a>
      </div>
      <div className="trusted-by-section">
        <div className="trusted-by-text">
          Trusted by Industry Leaders and Innovators
        </div>
        <Logos />
      </div>
    </section>
  );
};

export default Header;
