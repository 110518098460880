import React, { useState } from "react";
import Card, {
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../components/ui/Card";
import Button from "../components/ui/Button";
import Input from "../components/ui/Input";
import { Search } from "lucide-react";
import "./Blogs.css";
import Soda from "../assets/images/white-soda.png";
import { useNavigate } from "react-router-dom";
import blogPosts from "../data/posts/blogPosts";

const BlogPage = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredPosts = blogPosts.filter(
    (post) =>
      post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.tags.some((tag) =>
        tag.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="blog-page">
      <div className="blog-logo-container">
        <img
          src={Soda}
          className="blog-logo-img"
          alt="Soda Logo"
          onClick={() => navigate("/")}
        />
        <div className="blog-logo-text">
          Software Outsourcing Development & Architecture LTD.
        </div>
      </div>
      <main className="blog-container blog-main">
        <div className="blog-content">
          <div className="blog-main-content">
            <div className="blog-posts-list">
              {filteredPosts.map((post) => (
                <Card key={post.id} className="blog-featured-post">
                  <img
                    src={post.coverImage}
                    alt={post.title}
                    className="blog-featured-image"
                  />
                  <CardHeader>
                    <div className="blog-post-header">
                      <CardTitle>{post.title}</CardTitle>
                      <div className="span-date">
                        {post.readingTime} • {post.date}
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <p>{post.description}</p>
                    <div className="blog-tags">
                      {post.tags.map((tag) => (
                        <span key={tag} className="blog-tag">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </CardContent>
                  <CardFooter className="blog-post-footer">
                    <Button onClick={() => navigate(`/blog/${post.slug}`)}>
                      Read More
                    </Button>
                    <span className="span-date">By {post.author}</span>
                  </CardFooter>
                </Card>
              ))}
            </div>
          </div>

          <aside className="blog-sidebar">
            <Card>
              <CardHeader>
                <CardTitle>Search</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="blog-search-input">
                  <Input
                    type="search"
                    placeholder="Search posts..."
                    onChange={handleSearchChange}
                    value={searchTerm}
                  />
                  <Button type="submit" size="icon">
                    <Search className="h-4 w-4" />
                  </Button>
                </div>
              </CardContent>
            </Card>
          </aside>
        </div>
      </main>
    </div>
  );
};

export default BlogPage;
