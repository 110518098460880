import ReactGA4 from "react-ga4";

export const initGA = () => {
  ReactGA4.initialize("G-MLW85XTYCH", {
    testMode: false,
    gtagOptions: {
      debug_mode: false,
      send_page_view: false,
    },
  });
};

export const trackPageView = () => {
  window.gtag("event", "page_view", {
    page_title: document.title,
    page_location: window.location.href,
    page_path: window.location.pathname,
  });
};

export const trackSectionView = (sectionId: string, sectionName: string) => {
  window.gtag("event", "section_engagement", {
    section_id: sectionId,
    section_name: sectionName,
    engagement_time_msec: 1000,
  });
};

export const trackProjectView = (projectTitle: string) => {
  window.gtag("event", "project_engagement", {
    project_name: projectTitle,
    content_type: "project",
    engagement_time_msec: 1000,
  });
};

export const trackScrollDepth = (depth: number) => {
  window.gtag("event", "scroll_depth", {
    percent: depth,
    page_title: document.title,
  });
};

// Add type definition for gtag
declare global {
  interface Window {
    gtag: (
      command: string,
      eventName: string,
      params?: Record<string, any>
    ) => void;
  }
}
