import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "../components/ui/Card";
import { CardContent, CardHeader, CardTitle } from "../components/ui/Card";
import Button from "../components/ui/Button";
import Soda from "../assets/images/white-soda.png";
import blogPosts from "../data/posts/blogPosts";
import { ArrowUp } from "lucide-react";
import "./BlogPost.css";

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const post = blogPosts.find((post) => post.slug === slug);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 100;
      setShowScrollTop(scrolled);
      setIsScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!post) {
    return (
      <div className="blog-post-page">
        <header className="blog-post-header-container">
          <div className="blog-post-header-content">
            <div className="blog-post-logo" onClick={() => navigate("/")}>
              <img src={Soda} alt="Soda Logo" />
            </div>
          </div>
        </header>
        <main className="blog-post-container">
          <Card>
            <CardContent>
              <h1>Post not found</h1>
              <Button onClick={() => navigate("/blogs")}>Back to Blog</Button>
            </CardContent>
          </Card>
        </main>
      </div>
    );
  }

  return (
    <div className="blog-post-page">
      <header className="blog-post-header-container">
        <div className="blog-post-header-content">
          <div className="blog-post-logo" onClick={() => navigate("/")}>
            <img src={Soda} alt="Soda Logo" />
          </div>
          <Button onClick={() => navigate("/blogs")} variant="outline">
            Back to Blog
          </Button>
        </div>
      </header>

      <main className="blog-post-container">
        <article className="blog-post-article">
          <div className="blog-post-article-header">
            <h1 className="blog-post-title">{post.title}</h1>
            <div className="blog-post-meta">
              <span>{post.date}</span>
              <span className="blog-post-meta-divider">•</span>
              <span>{post.readingTime}</span>
              <span className="blog-post-meta-divider">•</span>
              <span>By {post.author}</span>
            </div>
            <div className="blog-post-tags">
              {post.tags.map((tag) => (
                <span key={tag} className="blog-post-tag">
                  {tag}
                </span>
              ))}
            </div>
          </div>

          <div className="blog-post-article-content">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: markdownToHtml(post.content) }}
            />
          </div>
        </article>

        {/* <button
          className={`blog-post-scroll-top ${showScrollTop ? "visible" : ""}`}
          onClick={scrollToTop}
          aria-label="Scroll to top"
        >
          <ArrowUp size={24} />
        </button> */}
      </main>
    </div>
  );
};

// Markdown to HTML converter
function markdownToHtml(markdown: string) {
  return markdown
    .replace(/^# (.*$)/gim, "<h1>$1</h1>")
    .replace(/^## (.*$)/gim, "<h2>$1</h2>")
    .replace(/^### (.*$)/gim, "<h3>$1</h3>")
    .replace(
      /!\[(.*?)\]\((.*?)\)/gim,
      '<img src="$2" alt="$1" class="blog-post-image" />'
    )
    .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
    .replace(/\*(.*?)\*/g, "<em>$1</em>")
    .replace(/\n\*(.*)/gim, "<li>$1</li>")
    .replace(/\n(\d+\. .*)/gim, "<li>$1</li>")
    .replace(/\n/g, "<br>");
}

export default BlogPost;
