import React from "react";
import "./MeetTheTeam.css";
import TeamCard from "./TeamCard/TeamCard";

import defaultUser from "../../assets/images/default-image-user.png";
import shachar from "../../assets/images/shachar_cohen_image.png";
import yossi from "../../assets/images/yossi_avni_image.png";
import danor from "../../assets/images/Danor_image.png";
import scott from "../../assets/images/scott_image.png";
import dean from "../../assets/images/dean_image.png";
import meitar from "../../assets/images/meitar_image.png";
import eytan from "../../assets/images/eytan_image.png";
import davit from "../../assets/images/Davit_image.png";
import itay from "../../assets/images/Itay_image.png";
import roee from "../../assets/images/Roee_image.png";

const MeetTheTeam: React.FC = () => {
  const teamMembers = [
    {
      imageSrc: yossi,
      name: "Yossi Avni",
      jobTitle: "Managing Partner, Cyber Security Expert, AI Expert",
    },
    {
      imageSrc: shachar,
      name: "Shachar Cohen",
      jobTitle: "Managing Partner, R&D Manager",
    },
    {
      imageSrc: eytan,
      name: "Eytan H.S.",
      jobTitle: "AI Expert, AI Researcher",
    },
    {
      imageSrc: defaultUser,
      name: "Guy B.",
      jobTitle: "Projects Manager, Data Eng. Developer",
    },
    {
      imageSrc: meitar,
      name: "Meitar T.",
      jobTitle: "Senior Full-Stack Developer",
    },
    {
      imageSrc: danor,
      name: "Danor S.",
      jobTitle: "Senior Full-Stack Developer",
    },
    {
      imageSrc: dean,
      name: "Dean T.",
      jobTitle: "Senior Data Scientist, Researcher",
    },
    {
      imageSrc: scott,
      name: "Scott G.",
      jobTitle: "Senior Full-Stack Developer",
    },
    {
      imageSrc: davit,
      name: "Davit D.",
      jobTitle: "Senior Full-Stack Developer",
    },
    {
      imageSrc: itay,
      name: "Itay S.",
      jobTitle: "Senior Full-Stack Developer",
    },
    {
      imageSrc: roee,
      name: "Roee S.",
      jobTitle: "Senior Full-Stack Developer",
    },
    // {
    //   imageSrc: defaultUser,
    //   name: "Nataly Avni",
    //   jobTitle: "Founder and CEO",
    // },
  ];

  return (
    <section id="meet-the-team" className="meet-the-team">
      <h2 className="meet-the-team__title">Meet Our Team Leaders</h2>
      <div className="meet-the-team__grid">
        {teamMembers.map((member, index) => (
          <TeamCard
            key={index}
            imageSrc={member.imageSrc}
            name={member.name}
            jobTitle={member.jobTitle}
          />
        ))}
      </div>
    </section>
  );
};

export default MeetTheTeam;
