import React, { useRef, useState, useEffect } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import SlidingBox from "./SlidingBoxes/SlidingBoxes";
import Modal from "./Modal/Modal";
import { projectsData } from "./projectsData";
import "./Projects.css";
import { trackProjectView } from "../../utils/analytics";

interface PopupContent {
  title: string;
  learnMoreText: string;
  imageUrls: string[];
  logos?: string[];
}

const Projects: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPopupContent, setSelectedPopupContent] = useState<
    PopupContent[] | null
  >(null);
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [previousSlide, setPreviousSlide] = useState(-1);
  const isMobile = (() => {
    const width = window.innerWidth;
    return width <= 480;
  })();
  const slideInterval = 3000; // 3 seconds per slide

  const marqueeRef = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(
    marqueeRef as React.MutableRefObject<HTMLDivElement>
  );
  const animationRef = useRef<number>();
  const scrollSpeed = 1; // Increased from 0.5 for smoother scrolling
  const RESET_DELAY = 5000; // 5 seconds before resetting to auto-scroll

  // Add this state to track the timer
  const [autoScrollTimer, setAutoScrollTimer] = useState<NodeJS.Timeout | null>(
    null
  );

  // Add these new states at the top with other states
  const [manualScrollPosition, setManualScrollPosition] = useState(0);
  const [autoScrollPosition, setAutoScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<
    "left" | "right" | null
  >(null);

  useEffect(() => {
    let lastTimestamp = 0;
    const FPS = 60;
    const frameDelay = 1000 / FPS;

    const autoScroll = (timestamp: number) => {
      if (!isUserInteracting && marqueeRef.current) {
        // Ensure smooth animation timing
        if (timestamp - lastTimestamp > frameDelay) {
          if (isMobile) {
            // Mobile behavior remains the same
            const nextSlide = (currentSlide + 1) % projectsData.length;
            setPreviousSlide(currentSlide);
            setCurrentSlide(nextSlide);
            setAutoScrollPosition(nextSlide);
            setManualScrollPosition(nextSlide);
          } else {
            // Improved desktop scrolling
            const container = marqueeRef.current;
            const maxScroll = container.scrollWidth - container.clientWidth;

            // Calculate new scroll position
            const newScrollLeft = container.scrollLeft + scrollSpeed;

            if (newScrollLeft >= maxScroll) {
              // Smooth reset to start
              container.scrollTo({
                left: 0,
                behavior: "auto",
              });
            } else {
              container.scrollLeft = newScrollLeft;
            }
          }
          lastTimestamp = timestamp;
        }
      }
      animationRef.current = requestAnimationFrame(autoScroll);
    };

    if (!isMobile) {
      animationRef.current = requestAnimationFrame(autoScroll);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isUserInteracting, currentSlide, isMobile]);

  const handleInteractionStart = () => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    // Clear any existing timer
    if (autoScrollTimer) {
      clearTimeout(autoScrollTimer);
    }
    setIsUserInteracting(true);
    setIsDragging(true);
  };

  const handleInteractionEnd = () => {
    setIsDragging(false);

    if (autoScrollTimer) {
      clearTimeout(autoScrollTimer);
    }

    // Add a longer delay before resuming auto-scroll
    const timer = setTimeout(() => {
      setAutoScrollPosition(manualScrollPosition);
      setIsUserInteracting(false);
    }, RESET_DELAY);

    setAutoScrollTimer(timer);
  };

  // Clean up the timer when component unmounts
  useEffect(() => {
    return () => {
      if (autoScrollTimer) {
        clearTimeout(autoScrollTimer);
      }
    };
  }, [autoScrollTimer]);

  const openModal = (projects: PopupContent[]) => {
    setSelectedPopupContent(projects);
    setModalOpen(true);

    // Track which project was viewed
    projects.forEach((project) => {
      trackProjectView(project.title);
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedPopupContent(null);
  };

  const handleSlideChange = (newIndex: number) => {
    setPreviousSlide(currentSlide);
    setCurrentSlide(newIndex);
  };

  useEffect(() => {
    if (isMobile && !isUserInteracting) {
      const interval = setInterval(() => {
        const nextSlide = (currentSlide + 1) % projectsData.length;
        setPreviousSlide(currentSlide);
        setCurrentSlide(nextSlide);
        setScrollDirection("left");
        setAutoScrollPosition(nextSlide);
        setManualScrollPosition(nextSlide);
      }, slideInterval);

      return () => clearInterval(interval);
    }
  }, [currentSlide, isMobile, isUserInteracting, projectsData.length]);

  const handleDotClick = (index: number) => {
    setIsUserInteracting(true);
    setManualScrollPosition(index);
    setAutoScrollPosition(index);
    setCurrentSlide(index);
    handleSlideChange(index);

    setTimeout(() => {
      setIsUserInteracting(false);
    }, 500);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (marqueeRef.current) {
        const scrollLeft = marqueeRef.current.scrollLeft;
        const slideWidth =
          marqueeRef.current.scrollWidth / (3 * projectsData.length);
        const newSlide =
          Math.round(scrollLeft / slideWidth) % projectsData.length;
        setCurrentSlide(newSlide);
      }
    };

    marqueeRef.current?.addEventListener("scroll", handleScroll);
    return () =>
      marqueeRef.current?.removeEventListener("scroll", handleScroll);
  }, []);

  const handleTouchStart = (e: React.TouchEvent) => {
    handleInteractionStart();
    const touch = e.touches[0];
    if (marqueeRef.current) {
      marqueeRef.current.dataset.startX = touch.clientX.toString();
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!marqueeRef.current?.dataset.startX) return;

    const touch = e.touches[0];
    const startX = parseInt(marqueeRef.current.dataset.startX);
    const diff = startX - touch.clientX;

    // Determine scroll direction
    const direction = diff > 0 ? "left" : "right";
    setScrollDirection(direction);

    if (Math.abs(diff) > 50) {
      const nextSlide =
        direction === "left"
          ? (currentSlide + 1) % projectsData.length
          : (currentSlide - 1 + projectsData.length) % projectsData.length;

      setPreviousSlide(currentSlide);
      setCurrentSlide(nextSlide);
      setManualScrollPosition(nextSlide);
      marqueeRef.current.dataset.startX = "";
    }
  };

  return (
    <section id="projects" className="projects">
      <div className="projects-title">
        Powering Innovation Across Industries
      </div>
      <div
        className={`marquee-container ${isMobile ? "mobile-view" : ""}`}
        {...events}
        ref={marqueeRef}
        style={{
          overflowX: "auto",
          cursor: isDragging ? "grabbing" : "grab",
          display: "flex",
          width: "100%",
          WebkitOverflowScrolling: "touch",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleInteractionEnd}
        onMouseEnter={handleInteractionStart}
        onMouseLeave={handleInteractionEnd}
      >
        {isMobile ? (
          <div className="mobile-slider">
            {projectsData.map((project, index) => (
              <div
                key={index}
                className={`project-container ${
                  currentSlide === index
                    ? "active"
                    : previousSlide === index
                    ? "previous"
                    : index === (currentSlide + 1) % projectsData.length
                    ? "entering-from-right"
                    : ""
                }`}
                style={{
                  zIndex: currentSlide === index ? 2 : 1,
                }}
              >
                <SlidingBox
                  project={project}
                  openModal={() =>
                    openModal(project.popupContents || [project.popupContent!])
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          // Desktop view: Show continuous scroll
          [0, 1, 2].map((setIndex) => (
            <div
              key={setIndex}
              style={{
                display: "flex",
                willChange: "transform",
                transform: "translate3d(0,0,0)",
              }}
            >
              {projectsData.map((project, index) => (
                <div
                  key={`${setIndex}-${index}`}
                  className="project-container"
                  style={{ minWidth: "420px" }}
                >
                  <SlidingBox
                    project={project}
                    openModal={() =>
                      openModal(
                        project.popupContents || [project.popupContent!]
                      )
                    }
                  />
                </div>
              ))}
            </div>
          ))
        )}
      </div>

      <div className="dots-container">
        {projectsData.map((_, index) => (
          <button
            key={index}
            className={`dot ${currentSlide === index ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>

      {selectedPopupContent && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          popupContents={selectedPopupContent}
        />
      )}
    </section>
  );
};

export default Projects;
