import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import About from "./components/About/About";
import Expertise from "./components/Expertise/Expertise"; // Import the new section
import Projects from "./components/Projects/Projects";
import Services from "./components/Services/Services";
import MeetTheTeam from "./components/MeetTheTeam/MeetTheTeam";
import Contact from "./components/Contact/Contact";
import AnimationContainer from "./components/AnimationContainer/AnimationContainer";
import { ScrollProvider, useScroll } from "./context/ScrollContext";
import {
  initGA,
  trackPageView,
  trackSectionView,
  trackScrollDepth,
} from "./utils/analytics";
import ReactGA4 from "react-ga4";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blogs from "./pages/Blogs";
import BlogPost from "./pages/BlogPost";

const AppContent = () => {
  const appRef = useRef<HTMLDivElement>(null);
  const prevScrollTopRef = useRef<number>(0); // Initialize the reference with 0
  const { setScrollPosition } = useScroll();
  const sectionsRef = useRef<Set<string>>(new Set());
  const [loading, setLoading] = useState(true);

  const handleScroll = () => {
    const currentScrollTop = appRef.current?.scrollTop || 0;
    setScrollPosition(currentScrollTop);
    prevScrollTopRef.current = currentScrollTop;

    // Track visible sections
    const sections = appRef.current?.querySelectorAll("section");
    sections?.forEach((section) => {
      const rect = section.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;

      if (isVisible && !sectionsRef.current.has(section.id)) {
        sectionsRef.current.add(section.id);
        trackSectionView(section.id, section.id);
      }
    });
  };

  useEffect(() => {
    // Initialize Google Analytics
    initGA();
    // console.log("Sending initial pageview");
    trackPageView();

    const div = appRef.current;
    div?.addEventListener("scroll", handleScroll);

    setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      div?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`App ${loading ? "loading" : ""}`} ref={appRef}>
      {loading ? (
        <div className="loading-screen">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          <AnimationContainer />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Navbar />
                  <Header />
                  <Projects />
                  <Expertise />
                  <Services />
                  <About />
                  <MeetTheTeam />
                  <Contact />
                </>
              }
            />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
          </Routes>
        </>
      )}
    </div>
  );
};

const App = () => (
  <ScrollProvider>
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  </ScrollProvider>
);

export default App;
