import React, { ReactNode } from "react";
import "./Button.css";

interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  variant?: "outline" | "ghost";
  size?: "icon" | "sm";
  type?: "button" | "submit";
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className,
  variant,
  size,
  type = "button",
}) => {
  let buttonClass = `button `;

  if (variant === "outline") {
    buttonClass += "button-outline";
  } else if (variant === "ghost") {
    buttonClass += "button-ghost";
  } else {
    buttonClass += "button-default";
  }

  if (size === "icon") {
    buttonClass += " button-icon";
  } else if (size === "sm") {
    buttonClass += " button-sm";
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${buttonClass} ${className || ""}`}
    >
      {children}
    </button>
  );
};

export default Button;
