import React, { ReactNode } from "react";
import "./Card.css";

interface CardProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ children, className, onClick }) => {
  return (
    <div className={`card ${className || ""}`} onClick={onClick}>
      {children}
    </div>
  );
};

export const CardHeader: React.FC<CardProps> = ({ children, className }) => {
  return <div className={`card-header ${className || ""}`}>{children}</div>;
};

export const CardTitle: React.FC<CardProps> = ({ children, className }) => {
  return <h3 className={`card-title ${className || ""}`}>{children}</h3>;
};

export const CardContent: React.FC<CardProps> = ({ children, className }) => {
  return <div className={`card-content ${className || ""}`}>{children}</div>;
};

export const CardFooter: React.FC<CardProps> = ({ children, className }) => {
  return <div className={`card-footer ${className || ""}`}>{children}</div>;
};

export default Card;
