import React, { useRef, useEffect } from "react";
import "./AnimationContainer.css";
import p5 from "p5";
import { useScroll } from "../../context/ScrollContext";

interface CustomP5 extends p5 {
  updateColors: (scrollPos: number) => void;
}

const AnimationContainer: React.FC = () => {
  const canvasRef = useRef<HTMLDivElement>(null);
  const sketchRef = useRef<CustomP5>();
  const { scrollPosition } = useScroll();

  const handleSketch = (p: CustomP5) => {
    let bubbles: Bubble[] = [];
    let bgGraphics: p5.Graphics;
    const cursorRadius = 30; // Increased impact radius
    let currentColor1: p5.Color = p.color("#3c6382");
    let currentColor2: p5.Color = p.color("#000000");

    const createBackground = (c1: p5.Color, c2: p5.Color) => {
      bgGraphics.background(10, 20, 40);
      for (let y = 0; y < p.height; y++) {
        const inter = p.map(y, 0, p.height, 0, 1);
        const c = p.lerpColor(c1, c2, inter);
        bgGraphics.stroke(c);
        bgGraphics.line(0, y, p.width, y);
      }
    };

    p.setup = () => {
      const canvas = p.createCanvas(p.windowWidth, p.windowHeight);
      canvas.parent(canvasRef.current!);
      p.pixelDensity(1);
      bgGraphics = p.createGraphics(p.width, p.height);
      createBackground(currentColor1, currentColor2);

      for (let i = 0; i < 500; i++) {
        bubbles.push(new Bubble(p, bgGraphics));
      }
    };

    p.draw = () => {
      createBackground(currentColor1, currentColor2);
      p.image(bgGraphics, 0, 0);

      bubbles.forEach((bubble, index) => {
        bubble.update();
        bubble.display();

        const d = p.dist(p.mouseX, p.mouseY, bubble.x, bubble.y);
        if (d < bubble.size / 2 + cursorRadius) {
          bubble.shootUp();
        }

        if (bubble.y < -50 || bubble.burst) {
          bubbles[index] = new Bubble(p, bgGraphics);
        }
      });
    };

    p.updateColors = (scrollPos: number) => {
      const windowHeight = window.innerHeight;

      const currentSection = Math.floor(scrollPos / windowHeight);

      let targetColor1: p5.Color;
      let targetColor2: p5.Color;

      switch (currentSection) {
        case 0:
          targetColor1 = p.color("#3c6382");
          targetColor2 = p.color("#000000");
          break;
        case 1:
          targetColor1 = p.color("#3c6382");
          targetColor2 = p.color("#000000");
          break;
        case 2:
          targetColor1 = p.color("#3c6382");
          targetColor2 = p.color("#000000");
          break;
        case 3:
          targetColor1 = p.color("#3c6382");
          targetColor2 = p.color("#000000");
          break;
        case 4:
          targetColor1 = p.color("#3c6382");
          targetColor2 = p.color("#000000");
          break;
        default:
          targetColor1 = p.color("#3c6382");
          targetColor2 = p.color("#000000");
      }

      currentColor1 = p.lerpColor(currentColor1, targetColor1, 0.6);
      currentColor2 = p.lerpColor(currentColor2, targetColor2, 0.6);
    };

    class Bubble {
      p: p5;
      bgGraphics: p5.Graphics;
      x: number;
      y: number;
      size: number;
      originalSize: number;
      speed: number;
      maxSpeed: number;
      wobble: number;
      wobbleSpeed: number;
      isWhite: boolean;
      color: p5.Color;
      alpha: number;
      burst: boolean;

      constructor(p: p5, bgGraphics: p5.Graphics) {
        this.p = p;
        this.bgGraphics = bgGraphics;
        this.x = 0;
        this.y = 0;
        this.size = 0;
        this.originalSize = 0;
        this.speed = 0;
        this.maxSpeed = 0;
        this.wobble = 0;
        this.wobbleSpeed = 0;
        this.isWhite = false;
        this.color = p.color(0);
        this.alpha = 0;
        this.burst = false;
        this.reset();
      }

      reset() {
        this.x = this.p.random(this.p.width);
        this.y = this.p.random(this.p.height + 50, this.p.height + 100);
        this.size = this.p.random(2, 7);
        this.originalSize = this.size;
        this.speed = this.p.map(this.size, 2, 7, 1.5, 5);
        this.maxSpeed = 10;
        this.wobble = this.p.random(this.p.TWO_PI);
        this.wobbleSpeed = this.p.random(0.05, 0.1);
        this.isWhite = this.p.random(100) < 40;
        this.color = this.isWhite
          ? this.p.color(255, 255, 255)
          : this.p.lerpColor(
              this.p.color(0, 50, 80),
              this.p.color(0, 200, 255),
              this.p.random()
            );
        this.alpha = this.isWhite ? 250 : this.p.map(this.size, 2, 7, 50, 180);
        this.burst = false;
      }

      shootUp() {
        this.speed = this.maxSpeed;
      }

      update() {
        this.y -= this.speed;
        this.wobble += this.wobbleSpeed;
        this.x += this.p.sin(this.wobble) * (this.speed * 0.3);

        if (this.p.random(1000) < 5) {
          this.speed *= this.p.random() > 0.5 ? 0.1 : 2;
        }

        if (this.speed > this.p.map(this.size, 2, 7, 1.5, 5)) {
          this.speed = this.p.lerp(
            this.speed,
            this.p.map(this.size, 2, 7, 1.5, 5),
            0.1
          );
        }

        if (this.y < -50 || this.size > this.originalSize * 2) {
          this.burst = true;
        }

        if (this.burst) {
          this.alpha -= 5;
          this.size += 0.1;
          if (this.alpha < 0) this.reset();
        }
      }

      display() {
        if (!this.burst) {
          this.p.push();
          this.p.translate(this.x, this.y);
          this.p.noStroke();

          const bubbleColor = this.color;
          bubbleColor.setAlpha(this.alpha * 0.6);
          this.p.fill(bubbleColor);
          this.p.ellipse(0, 0, this.size);

          if (this.isWhite) {
            const whiteColor = this.p.color(255, 255, 255, this.alpha);
            this.p.fill(whiteColor);
            this.p.ellipse(-this.size * 0.2, -this.size * 0.2, this.size * 0.6);
            this.p.stroke(255, 255, 255, this.alpha * 0.3);
            this.p.noFill();
            this.p.arc(
              0,
              0,
              this.size * 0.8,
              this.size * 0.8,
              this.p.PI * 0.8,
              this.p.PI * 1.4
            );
          }

          this.p.pop();
        }
      }
    }

    p.windowResized = () => {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
      if (bgGraphics) {
        bgGraphics.resizeCanvas(p.windowWidth, p.windowHeight);
        createBackground(currentColor1, currentColor2);
      }
    };
  };

  useEffect(() => {
    if (!canvasRef.current) return;
    sketchRef.current = new p5(handleSketch as any) as CustomP5;

    return () => {
      sketchRef.current?.remove();
    };
  }, []);

  useEffect(() => {
    sketchRef.current?.updateColors(scrollPosition);
  }, [scrollPosition]);

  return <div ref={canvasRef} id="animation-container"></div>;
};

export default AnimationContainer;
