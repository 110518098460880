// projectsData.ts
import medtech from "../../assets/images/medtech.png";
import realestate from "../../assets/images/realestate.png";
import security from "../../assets/images/security.png";
import defence from "../../assets/images/defence.png";
import aerospace from "../../assets/images/aerospace.png";
import telecoms from "../../assets/images/telecoms.png";
import algotrading from "../../assets/images/algotrading.png";
import fintech from "../../assets/images/fintech.png";
import autonomousdriving from "../../assets/images/autonomousdriving.png";
import iot from "../../assets/images/iot.png";
import behavioralProfiling from "../../assets/images/Behavioral-Profiling.png";

//project images
import algotrading1 from "../../assets/images/projects/algotrading1.png";
import algotrading2 from "../../assets/images/projects/algotrading2.jpg";
import algotrading3 from "../../assets/images/projects/algotrading3.jpg";
import autovision1 from "../../assets/images/projects/autovision1.jpg";
import autovision2 from "../../assets/images/projects/autovision2.jpg";
import cyber1 from "../../assets/images/projects/cyber1.jpg";
import medtech1 from "../../assets/images/projects/medtech1.jpg";
import medtech2 from "../../assets/images/projects/medtech2.jpg";
import medtech3 from "../../assets/images/projects/medtech3.jpg";
import medtech4 from "../../assets/images/projects/medtech4.jpg";
import defense from "../../assets/images/projects/defense.png";
import defense1 from "../../assets/images/projects/defence1.png";
import aerospace1 from "../../assets/images/projects/aerospace.png";
import dimensions1 from "../../assets/images/projects/dimensions1.png";
import dimensions2 from "../../assets/images/projects/dimensions2.png";
import dimensions3 from "../../assets/images/projects/dimensions3.png";
import realestate1 from "../../assets/images/projects/realestate1.jpg";
import medical1 from "../../assets/images/projects/medical1.png";
import medical2 from "../../assets/images/projects/medical2.png";
import zsmile1 from "../../assets/images/projects/zsmile1.png";
import activeaging1 from "../../assets/images/projects/activeaging1.png";
import activeaging2 from "../../assets/images/projects/activeaging2.png";
import activeaging3 from "../../assets/images/projects/activeaging3.png";
import vehicles1 from "../../assets/images/projects/vehicles1.png";
import vehicles2 from "../../assets/images/projects/vehicles2.png";
import vehicles3 from "../../assets/images/projects/vehicles3.png";
import hot1 from "../../assets/images/projects/hot1.png";
import hot2 from "../../assets/images/projects/hot2.png";
import hot3 from "../../assets/images/projects/hot3.png";

import Clew from "../../assets/images/clew.svg";
import Cybring from "../../assets/images/cybring.svg";
import IAI_ELTA from "../../assets/images/IAI_ELTA.svg";
import IAI_logo from "../../assets/images/IAI_logo.svg";
import Icarbonx from "../../assets/images/icarbonx.svg";
import Leventech from "../../assets/images/leventech.svg";
import Hot from "../../assets/images/logo-hot.svg";
import ODM from "../../assets/images/ODM_logo.svg";
import Pi from "../../assets/images/pi.svg";
import Rafael from "../../assets/images/rafael.svg";
import Skinapp from "../../assets/images/Skinapp_logo_black.svg";
import Splitit from "../../assets/images/splitit.svg";
import Zsmile from "../../assets/images/ZsmileLogo.svg";
import ActiveAging from "../../assets/images/Active_Aging_Logo_White.svg";

interface PopupContent {
  title: string;
  learnMoreText: string;
  imageUrls: string[];
  logos?: string[];
}

interface Project {
  title: string;
  description: string;
  imageUrl: string;
  logos?: string[];
  popupContent?: PopupContent; // Single popup content (optional)
  popupContents?: PopupContent[]; // Multiple popup contents (optional)
}

export const projectsData: Project[] = [
  {
    title: "Telecommunications",
    description:
      "Innovating in the telecommunications sector with advanced AI-driven tools and secure communication platforms, ensuring robust and efficient connectivity.",
    imageUrl: telecoms,

    popupContent: {
      title: "Telecommunications - HOT App for Technicians",
      learnMoreText:
        "We successfully developed and deployed a custom telecommunications management application for HOT Mobile. This project focused on creating a robust and secure tool that allowed network technicians to efficiently manage and report faults as well as pre-scheduled maintenance tasks in real time. The application was designed with a strong emphasis on user experience, integrating secure authentication and real-time data updates. It also included GPS capabilities to ensure precise location tracking of reported issues. Our solution enhanced operational efficiency by streamlining the workflow for technicians, enabling them to log tasks, update statuses, and generate reports directly from their mobile devices.",
      imageUrls: [hot1, hot2, hot3],
      logos: [Hot],
    },
  },
  {
    title: "Autonomous Vehicles",
    description:
      "Pioneering AI-driven technology in autonomous vehicles with a patented selective-attention technology using optical and Lidar signal fusion, enabling efficient processing on low-consumption CPU/GPU systems.",
    imageUrl: autonomousdriving,
    popupContents: [
      {
        title: "Autonomous Vehicles - Selective Attention AI",
        learnMoreText:
          "This project showcases our work in autonomous driving, where AI-powered systems are used to navigate vehicles safely through complex environments. The video demonstrates the capabilities of our autonomous driving technology, including obstacle detection, route planning, and real-time decision-making, highlighting the future of self-driving cars.",
        imageUrls: [
          vehicles1,
          vehicles2,
          vehicles3,
          autovision1,
          autovision2,
          realestate1,
          "https://drive.google.com/file/d/1hKLWVkgS8KkbhpHTF1onWafAT3LIaYeR/",
          "https://drive.google.com/file/d/1bW9CTKAqB3xZIRlgcqPT-jZljndWi8yI/",
          "https://drive.google.com/file/d/1ZhsO3FraLxI5XaKIDlPFjAOlAIp2YuIk/view?usp=sharing",
        ],
        logos: [],
      },
    ],
  },
  {
    title: "Med Tech",
    description:
      "Revolutionizing healthcare with AI-driven diagnostic tools and advanced image processing, enhancing early detection and treatment planning.",
    imageUrl: medtech,

    popupContents: [
      {
        title: "Medical AI-Based Trained Models For Multi Disiplines",
        learnMoreText:
          "Our extensive work in the Med Tech sector has included the development of cutting-edge AI tools designed to push the boundaries of medical diagnostics. In addition to the brain CT semantic segmentation engine, we've collaborated with healthcare providers to create AI models for remote patient monitoring and smart ventilation systems that collect secure medical data for cloud-based analysis. We've also worked on the classification of arterial calcification in the carotid artery—a key early indicator of severe strokes. Other notable projects include the early detection of osteoporosis and osteopenia in older women, advanced imaging in echocardiography and CT, and providing early warnings for delirium deterioration in intensive care settings. Additionally, our AI models have been used for the early detection of asthma and lung diseases in children and babies, and to improve cancer treatment by analyzing mutations for optimized chemotherapy.",
        imageUrls: [],
        logos: [],
      },
      {
        title: "Cardio Failure Prediction",
        learnMoreText:
          "The Cardio Failure Prediction project focuses on developing advanced algorithms that predict cardiac events in patients with a high degree of accuracy. By analyzing vast datasets from wearable devices and electronic health records, our system can identify early warning signs of heart failure, enabling timely intervention and potentially saving lives.",
        imageUrls: [medical1],
        logos: [],
      },
      {
        title: "Metabolic Syndrome Profiling",
        learnMoreText:
          "Metabolic Syndrome Profiling involves the creation of comprehensive health profiles for individuals at risk of metabolic syndrome. Using AI-driven analytics, this project identifies key risk factors such as obesity, hypertension, and insulin resistance, helping healthcare providers to develop personalized treatment plans to mitigate these risks.",
        imageUrls: [activeaging1, activeaging2, activeaging3],
        logos: [ActiveAging],
      },
      {
        title: "Ventilation Machine",
        learnMoreText:
          "Our Ventilation Machine project is aimed at enhancing the functionality and efficiency of mechanical ventilators. This project integrates AI technologies to optimize ventilation settings for individual patients, improving outcomes for those with severe respiratory conditions, especially in critical care settings.",
        imageUrls: [
          medical2,
          "https://drive.google.com/file/d/15lm30df41ZDtIR4wi3szNgzVblGqjwBA/view?usp=sharing",
          "https://drive.google.com/file/d/16Huxwr_0hzrS1oQNwkrx7DeRmqfG6ozV/view?usp=sharing",
        ],
        logos: [Leventech],
      },
      {
        title: "Echo Cardio CT Scan",
        learnMoreText:
          "The Echo Cardio CT Scan project is dedicated to advancing cardiovascular imaging techniques. By combining echocardiography with CT scanning, this project allows for detailed visualization of heart structures and function, aiding in the early detection of cardiovascular diseases and improving the accuracy of diagnoses.",
        imageUrls: [medtech3, medtech4],
        logos: [],
      },
      {
        title: "Intelligent Statoscope",
        learnMoreText:
          "The Intelligent Statoscope project focuses on the development of advanced medical devices that enhance the accuracy and efficiency of cardiac diagnostics. This project integrates sophisticated AI algorithms with traditional stethoscope functionality, allowing healthcare providers to detect and analyze heart sounds with unprecedented precision. The device not only captures standard heart sounds (S1 and S2) but also identifies and analyzes murmurs and other irregularities that may indicate underlying heart conditions. By combining phonocardiogram (PCG) signals with electrocardiogram (ECG) data, the Intelligent Stethoscope can offer a comprehensive analysis of heart function in real-time.",
        imageUrls: [medtech1, medtech2],
        logos: [],
      },
      {
        title: "Zsmile",
        learnMoreText: "",
        imageUrls: [zsmile1],
        logos: [Zsmile],
      },
    ],
  },

  {
    title: "Defense",
    description:
      "Partnering with leading defense contractors to develop cutting-edge systems, enhancing national security through innovative software solutions.",
    imageUrl: defence,
    popupContent: {
      title: "Defense Projects - AI Models - Classified",
      learnMoreText:
        "Our contributions to the Defense sector are pivotal in advancing national security technologies. We've developed sophisticated AI models that classify radar signals and sound signatures, crucial for operational defense systems. These classifiers are capable of distinguishing between various radar types, as well as identifying sniper shots by direction and distance. Our work also includes classified projects related to advanced imaging technologies and the development of accurate navigation systems that operate without GPS, providing crucial capabilities in environments where traditional navigation systems may be compromised. Additionally, we've integrated these technologies into military-grade projects, ensuring they meet the rigorous demands of defense operations.",
      imageUrls: [defense1],
      logos: [Rafael],
    },
  },
  {
    title: "Aerospace",
    description:
      "Elevating aerospace intelligence with cutting-edge recognition systems, empowering unprecedented situational awareness and decision-making in the skies.",
    imageUrl: aerospace,

    popupContent: {
      title: "Radar Classification",
      learnMoreText:
        "In the Aerospace industry, our expertise lies in developing advanced recognition systems that significantly enhance situational awareness and decision-making capabilities. These systems leverage AI to process and analyze data in real-time, enabling quicker and more accurate responses to dynamic conditions in the aerospace environment. A key project under this domain is our Radar Classification system, which processes Synthetic Aperture Radar (SAR) data to classify various types of ships. This system can determine the type of vessel, its distance, and its size with remarkable precision, providing crucial information for both civilian and defense applications. By integrating these technologies, we've helped to improve the safety, efficiency, and effectiveness of aerospace operations, ensuring that decision-makers have the most accurate and timely information at their fingertips.",
      imageUrls: [aerospace1],
      logos: [IAI_ELTA],
    },
  },
  {
    title: "Cybersecurity",
    description:
      "Architecting next-gen digital fortresses with AI-powered threat intelligence, setting new benchmarks in proactive defense and rapid response capabilities.",
    imageUrl: security,

    popupContent: {
      title: "Cybersecurity Projects",
      learnMoreText:
        "Our work in Cybersecurity involves the development of state-of-the-art, AI-powered threat intelligence systems that are designed to detect and neutralize cyber threats before they can cause harm. We have engineered secure messaging platforms that utilize advanced encryption techniques, allowing for seamless integration with popular third-party applications such as WhatsApp and Messenger. Beyond this, we've also created classifiers that analyze log events and sequences in real-time, offering robust defense mechanisms against specific cyber-attacks, particularly in cloud environments. These technologies have been integrated into operational systems for leading defense and cybersecurity companies, setting new standards for digital security.",
      imageUrls: [cyber1],
      logos: [Cybring],
    },
  },
  {
    title: "Behavioral Profiling",
    description:
      "Uncovering deep insights into individual and group behaviors through advanced profiling techniques. Our systems leverage AI and big data analytics to create detailed profiles that help predict actions, preferences, and behaviors in various contexts, from retail and marketing to law enforcement and national security.",
    imageUrl: behavioralProfiling,
    popupContent: {
      title: "Behavioral Profiling Projects",
      learnMoreText:
        "Our work in behavioral profiling focuses on developing sophisticated models that analyze and predict human behavior. By understanding key characteristics and patterns—such as cultural practices, purchasing habits, and social behaviors—we enable organizations to make data-driven decisions. These insights are invaluable for tailoring marketing strategies, improving customer engagement, and enhancing public safety. In law enforcement and security, these tools can predict potential threats and identify persons of interest based on behavioral patterns, enhancing situational awareness and response capabilities.",
      imageUrls: [dimensions1, dimensions2, dimensions3],
    },
  },
  {
    title: "Algo Trading",
    description:
      "Developing cutting-edge algo-trading robots utilizing RNN and LSTM models optimized with reinforcement learning, enabling real-time trading trend predictions for the financial markets.",
    imageUrl: algotrading,
    popupContent: {
      title: "Algo Trading Projects",
      learnMoreText:
        "In the financial markets, our algo-trading solutions are at the forefront of innovation. We develop advanced trading robots that leverage Recurrent Neural Networks (RNN) and Long Short-Term Memory (LSTM) models, which are optimized through reinforcement learning. These robots are capable of making real-time trading decisions based on market trends, offering a significant edge in high-frequency trading environments. Our technology allows traders to predict market movements with greater accuracy, reducing risks and enhancing profitability. This system has been successfully deployed in various financial institutions, proving its effectiveness in the fast-paced world of algorithmic trading.",
      imageUrls: [algotrading1, algotrading2, algotrading3],
    },
  },

  {
    title: "Fintech",
    description:
      "Spearheading financial innovation by developing advanced fintech solutions, including full clearing systems with CRM for international credit payments (e.g., SplitIt) and KYC applications using multimodal biometrics for enhanced security and compliance.",
    imageUrl: fintech,
    popupContent: {
      title: "Fintech Projects",
      learnMoreText:
        "Our Fintech projects are designed to push the boundaries of financial innovation. We have developed comprehensive clearing systems that include CRM capabilities, such as the system created for SplitIt, which facilitates international credit payments in installments. Additionally, our work includes the creation of advanced Know Your Customer (KYC) applications that use multimodal biometrics to enhance security and ensure compliance with international regulations. These solutions provide financial institutions with the tools they need to operate more securely and efficiently in a highly regulated environment.",
      imageUrls: [],
      logos: [Splitit], // Algo Trading Logo
    },
  },

  {
    title: "IoT Devices",
    description:
      "Integrating IoT solutions across multiple platforms, from business applications to military-grade projects, including the development of computer vision algorithms and advanced GPS intelligence systems for precise reconnaissance.",
    imageUrl: iot,
    popupContent: {
      title: "IoT Devices Projects",
      learnMoreText:
        "Our expertise in IoT Devices extends to the integration of these technologies across a broad spectrum of applications, from commercial business solutions to military-grade systems. We've developed computer vision algorithms that enhance the capabilities of IoT devices, enabling them to perform tasks such as image recognition and analysis with high precision. In addition, our work includes the development of advanced GPS intelligence systems used for precise reconnaissance in military operations. These systems are designed to operate in challenging environments, providing reliable data and insights that are crucial for mission success. Our IoT solutions are highly customizable, ensuring they meet the specific needs of each application, whether in the business or defense sectors.",
      imageUrls: [],
    },
  },
  {
    title: "Real Estate",
    description:
      "Developing intelligent property management systems and predictive analytics tools to optimize asset performance and streamline operations.",
    imageUrl: realestate,
    popupContent: {
      title: "Real Estate Projects",
      learnMoreText:
        "In the Real Estate sector, our focus has been on creating intelligent property management systems that integrate AI-driven predictive analytics. These systems enable real-time monitoring and optimization of asset performance, providing property managers with the insights needed to streamline operations and maximize ROI. Our solutions incorporate advanced machine learning algorithms to forecast market trends, assess risks, and automate decision-making processes, making property management more efficient and data-driven.",
      imageUrls: [],
    },
  },
];
